






































































































.fx-15 {
  font-size: 15px;
  font-weight: bold;
}
.fx-20 {
  font-size: 20px;
  font-weight: bold;
}
.ratioBar {
  color: #f9ff00;
  text-transform: capitalize;
  font-family: 'UTM Helve';
  margin-top: 10px;
  margin-bottom: 0;
  padding: 5px;
  position: relative;
  .current-value {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
  }
  .label-prog {
    position: absolute;
    top: -10px;
    font-weight: 600;
  }
  .bar {
    height: 15px;
    width: 100%;
    border-radius: 10px;

    background-color: #ffffffad;
    overflow: hidden;

    .currentValue {
      background: linear-gradient(
        to right,
        rgba(23, 192, 1, 1) 0%,
        rgba(0, 56, 1, 1) 100%
      );
      height: 15px;
      border-radius: 10px;
      &.available {
        box-shadow: 1.2794px 1.2794px 1.2794px rgba(0, 0, 0, 0.3);
      }
    }
  }
  .bar.white {
    background-color: white !important;
  }
  .info {
    font-size: 0.8em;
  }
}
.current-value,
.text-default-value {
  font-size: 16px;
  font-weight: 700;
}
.pt-3 {
  padding-top: 1.25rem;
}
.justify-content-between {
  justify-content: space-between;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
